<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content" v-if="!viLoader">
      <div class="webNew-dispflex">
        <div class="backbox">
          <div class="back-arrow" @click="previousRoute">
            <i
              class="fa fa-arrow-left"
              style="padding-top: 11px; padding-left: 11px"
            ></i>
          </div>
        </div>
        <h4 class="hdrtitle backHeading" style="margin-top: 0px">School Hub</h4>
      </div>
      <div class="row mt-2">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" style="padding-left: 11px"
                  ><i class="fas fa-book-reader"></i
                ></span>
                <h1>Manage Terms/Grade Setting</h1>
              </div>

              <div class="mt-1" v-if="currentTab == 1">
             
              </div>
              <div class="mt-1" v-if="currentTab == 2">
                <button
                  style="width: 86%; margin-left: 5px"
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="updateGradeSetUp"
                >
                  <span>Update</span>
                </button>
              </div>
            </div>
            <div class="p-3 widjetcontent">
              <div class="tabs" style="height: 2%; background: none">
                <input
                  type="radio"
                  name="tabs"
                  @click="setActiveTab(1)"
                  id="classesSection"
                  :checked="currentTab == 1 ? 'checked' : ''"
                />
                <label for="classesSection" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Terms</label>

                <div class="tab">
                  <div style="border: 1px inset">
                    <form
                      data-vv-scope="classEditvalidate"
                      id="stuprofile"
                      accept-charset="utf-8"
                    >
                      <div
                        class="row cust-bg"
                        style="
                          background: #e3ecff;
                          border-radius: 6px;
                          margin: 8px;
                        "
                      >
                        <div
                          class="col-2 cell"
                          style="border-top: 0px solid #efefef"
                        >
                          Class
                        </div>
                        <div
                          class="col-1 cell"
                          style="border-top: 0px solid #efefef"
                        >
                          Term
                        </div>
                        <div
                          class="col-2 cell"
                          style="border-top: 0px solid #efefef"
                        >
                          START DATE
                        </div>
                        <div
                          class="col-2 cell"
                          style="border-top: 0px solid #efefef"
                        >
                          END DATE
                        </div>
                        <div
                          class="col-3 cell"
                          style="border-top: 0px solid #efefef"
                        >
                          PLANNED WORKING DAYS
                        </div>
                        <div
                          class="col-2 cell"
                          style="
                            border-top: 0px solid #efefef;
                            text-align: center;
                          "
                        >
                          Action
                        </div>
                      </div>
                      <div
                        v-for="(data, index) in Object.keys(termList)"
                        :key="index" style="border-style: ridge;"
                      >
                        <div
                          class="row cust-lr"
                          style="margin-right: 0px; margin-left: 0px"
                        >
                          <div class="col-2 cell">
                            {{
                              termList[data].class
                                ? termList[data].class.className
                                : ""
                            }}
                          </div>
                          <div class="col-1 cell">
                            <span v-if="!termList[data].isView">{{
                              termList[data][data] &&
                              termList[data][data].length > 0
                                ? termList[data][data][0].name
                                : ""
                            }}</span>
                          </div>

                          <div class="col-2 cell">
                            <span v-if="!termList[data].isView">{{
                              termList[data][data] &&
                              termList[data][data].length > 0
                                ? termList[data][data][0].startDate
                                : ""
                            }}</span>
                          </div>
                          <div class="col-2 cell">
                            <span v-if="!termList[data].isView">{{
                              termList[data][data] &&
                              termList[data][data].length > 0
                                ? termList[data][data][0].endDate
                                : ""
                            }}</span>
                          </div>
                          <div class="col-3 cell" style="padding-left: 82px">
                            -
                            <!-- <span v-if="!termList[data].isView">{{ termList[data][data] && termList[data][data].length > 0 ? termList[data][data][0].status : '' }}</span> -->
                          </div>

                          <div class="col-2 cell" style="text-align: center">
                            <button
                              v-if="termList[data].isView"
                              class="btn round orange tooltipt"
                              style="background: #b3a995; margin-left: 72px"
                              @click.prevent="showViewClose(data)"
                            >
                              <i class="fas fa-angle-up"></i>
                            </button>
                            <button
                              v-if="!termList[data].isView"
                              class="btn round orange tooltipt"
                              style="background: #b3a995"
                              @click.prevent="showView(data)"
                            >
                              <i class="fas fa-angle-down"></i>
                            </button>

                            <!-- <button
                        v-if="!termList[data].isView"
                        class="btn round red tooltipt"
                        @click.prevent="confirmDelete(termList[data].subject._id,selectTerm)"
                      
                      >
                        <i class="far fa-trash-alt"></i>
                        <span class="tooltiptext">Delete</span>
                      </button> -->
                            <!-- <button
                      v-if="termList[data].isView"
                        style="width: 67px"
                        type="button"
                        class="btn btnsml"
                        id="svbtn"
                      ><span>Add</span>
                        
                      </button> -->
                            <button
                              v-if="termList[data].isView"
                              style="width: 70px"
                              type="button"
                              class="btn btnsml"
                              id="svbtn"
                              @click.prevent="saveTerm(data,termList[data][data])"

                            >
                              Save
                            </button>
                          </div>
                        </div>
                        <div v-if="termList[data].isView">
                          <div
                            class="row cust-lr"
                            style="margin-right: 0px; margin-left: 0px"
                            v-for="(ter, issndex) in termList[data][data]"
                            :key="issndex"
                          >
                            <div class="col-2 cell"></div>
                            <div class="col-1 cell" style="padding-top: 16px">
                              {{ ter.name }}
                            </div>
                            <div
                              class="col-2 cell"
                              data-title="Teacher"
                              v-if="issndex == 0"
                            >
                              <!-- @input="setTermEditDates(ter.startDate,ter.endDate,issndex,'start')"
                            type="date"
                            :min="minDate"
                            :max="maxDate"
                            :name="issndex + 'editstart'" -->
                              <input
                                v-validate="'required'"
                                v-model="ter.startDate"
                                onkeydown="return false"
                                class="form-control"
                                type="date"
                                id="t1invdateid"
                                :min="minDate"
                                :max="maxDate"
                              />
                              <label
                                v-if="
                                  errors.first(
                                    `classEditvalidate.${issndex + 'editstart'}`
                                  )
                                "
                                class="vi-error"
                                style="top: -1em"
                                >Start Date is required</label
                              >
                            </div>
                            <div class="col-2 cell" data-title="Teacher" v-else>
                            
                              <input
                                type="date"
                                :name="issndex + 'editstart'"
                                v-validate="'required'"
                                :max="maxDate"
                                :disabled="(!termList[data][data][issndex - 1].startDate || !termList[data][data][issndex - 1].endDate)"
                                 :min="setminStartDate(termList[data][data][issndex - 1].endDate)"
                                v-model="ter.startDate"
                                onkeydown="return false"
                                class="form-control"
                                id="t1invdateid"
                              />
                              <label
                                v-if="
                                  errors.first(
                                    `classEditvalidate.${issndex + 'editstart'}`
                                  )
                                "
                                class="vi-error"
                                style="top: -1em"
                                >Start Date is required</label
                              >
                            </div>
                            <div class="col-2 cell" data-title="Teacher" v-if="issndex == 0">
                           <input
                            type="date"
                            :min="ter.startDate"
                            :max="maxDate"
                            v-model="ter.endDate"
                            @input="setTermEditDates(ter.startDate,ter.endDate,issndex,'end')"
                            onkeydown="return false" 
                            v-validate="'required'"
                            :name="issndex + 'editend'"
                            class="form-control"
                            id="t1invdateid"
                          />
                          <label
                            v-if="errors.first(`classEditvalidate.${index + 'editend'}`)"
                            class="vi-error"
                            style="top: -1em"
                            >End Date is required</label
                          >
                        </div>
                        <div class="col-2 cell" data-title="Teacher" v-else>
                          <!-- @input="setTermEditDates(data.startDate,data.endDate,issndex,'end')"
                          :disabled="(!editTermList[issndex - 1].startDate || !editTermList[issndex - 1].endDate || !data.startDate)" -->


                           <input
                            type="date"
                            :min="ter.startDate"
                            :max="maxDate"

                            v-model="ter.endDate"
                            onkeydown="return false" 
                            v-validate="'required'"
                            :name="issndex + 'editend'"
                            class="form-control"
                            id="t1invdateid"
                          />
                          <label
                            v-if="errors.first(`classEditvalidate.${index + 'editend'}`)"
                            class="vi-error"
                            style="top: -1em"
                            >End Date is required</label
                          >
                        </div>

                            <!-- <div class="col-2 cell"></div>
                    <div class="col-2 cell"></div> -->
                            <!-- <div class="col-2 cell">
                              <input
                                type="date"
                                :min="ter.startDate"
                                :max="maxDate"
                                v-model="ter.endDate"
                                onkeydown="return false"
                                v-validate="'required'"
                                :name="issndex + 'editend'"
                                class="form-control"
                                id="t1invdateid"
                              />
                            </div> -->
                            <div
                              class="col-3 cell"
                              style="padding-left: 82px; padding-top: 16px"
                            >
                              {{ noOfDaysLeave(ter.startDate, ter.endDate) }}
                            </div>

                            <div class="col-2 cell" style="text-align: center">
                              <b-dropdown
                              id="dropdown-1"
                              text=""
                              style="margin-right: 3px"
                            >
                           
                              <b-dropdown-item  
                                
                                @click.prevent="addTerms(data)"
                              
                                >Add</b-dropdown-item
                              >
                              <b-dropdown-item  v-if="issndex > 0"  @click.prevent="confirmDelete(ter._id,issndex,data)"
                              
                                >Delete</b-dropdown-item
                              >
                              
                            </b-dropdown>

                              <!-- <b-icon
                                icon="plus-circle"
                                variant="primary"
                                style="
                                  height: 25px;
                                  margin-right: 5px;
                                  cursor: pointer;
                                "
                                v-if="
                                  termList[data][data].length == issndex + 1
                                "
                                @click.prevent="addTerms(data)"
                              ></b-icon>
                              <b-icon
                                v-if="issndex > 0"
                                icon="dash-circle"
                                variant="danger"
                                style="height: 25px; cursor: pointer"
                                @click.prevent="confirmDelete(ter._id,issndex,data)"
                              ></b-icon> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <input
                  type="radio"
                  name="tabs"
                  :checked="currentTab == 2 ? 'checked' : ''"
                  @click="setActiveTab(2)"
                  id="assignedClass"
                />
                <label for="assignedClass" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Grade Setting</label>
                <div class="tab">
                  <div class="tbllist">
                    <div class="crtfeeplancont" style="padding: 0 13px">
                      <div
                        class="widjethdr dispflex"
                        style="align-items: baseline; padding: 10px 0"
                      ></div>
                      <div class="restable">
                        <div class="resrow resheader">
                          <!-- <div class="cell">Type</div> -->
                          <div class="cell">Grade</div>
                          <div class="cell">From</div>
                          <div class="cell">To</div>
                          <div class="cell">Scale</div>

                          <div class="cell">Action</div>
                        </div>
                        <div
                          class="resrow"
                          v-for="(data, index) in allGradeList.gradeMarkList"
                          :key="index"
                        >
                          <!-- <div class="cell">
                  {{ data.gradeType  }}
                </div> -->
                          <div class="cell">
                            <input
                              v-model="data.grade"
                              type="text"
                              class="form-control"
                              id="stdFirstName"
                            />
                          </div>
                          <div class="cell" data-title="getUserName">
                            <input
                              v-model="data.from"
                              type="text"
                              class="form-control"
                              id="stdFirstName"
                            />
                          </div>
                          <div class="cell" data-title="Role">
                            <input
                              v-model="data.to"
                              type="text"
                              class="form-control"
                              id="stdFirstName"
                            />
                          </div>
                          <div class="cell" data-title="Role">
                            <input
                              v-model="data.scale"
                              type="text"
                              class="form-control"
                              id="stdFirstName"
                            />
                          </div>
                          <div class="cell">
                            <!-- <button @click.prevent="openGradingMarkEditPopUp(index)"
                    class="btn round orange tooltipt"
                  >
                    <i class="fas fa-minus-circle"></i
                    ><span class="tooltiptext">Remove</span>
                  </button>

                  <button class="btn rndbtn"  v-if="allGradeList.gradeMarkList.length == ( index + 1)" @click.prevent="openGradingMarkPopUp"><i class="fas fa-plus-circle"></i></button> -->

                            <b-icon
                              icon="plus-circle"
                              variant="primary"
                              style="
                                height: 25px;
                                margin-right: 5px;
                                cursor: pointer;
                              "
                              v-if="
                                allGradeList.gradeMarkList.length == index + 1
                              "
                              @click.prevent="openGradingMarkPopUp"
                            ></b-icon>
                            <b-icon
                              icon="dash-circle"
                              variant="danger"
                              style="height: 25px; cursor: pointer"
                              @click.prevent="openGradingMarkEditPopUp(index)"
                            ></b-icon>
                          </div>
                        </div>
                      </div>
                      <!-- <div
              class="widjet"
              style="height: 198px; box-shadow: none"
            >
              <div style="padding-top: 92px">
                <span style="margin-left: 42%">No Data Found</span>
              </div>
            </div> -->
                    </div>
                  </div>
                  <!-- <gradesetting/> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-modal id="gradeSetting" style="width: 40%" size="sm">
        <!-- <div class="modal-content"> -->
        <button
          type="button"
          class="close"
          @click.prevent="closeGradingMarkPopUp"
        >
          ×
        </button>

        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Grade Setting</h2>
          <br />
          <div class="form-row">
            <div class="form-group input-group col-md-6 mt-3">
              <span class="has-float-label">
                <select
                  class="form-control form-input"
                  id="Reportingid"
                  disabled
                  name="Reporting"
                  v-model="gradeType"
                  @change="clearValues"
                >
                  <option :value="null">-- Select --</option>
                  <option
                    v-for="(item, index) in gradeTypes"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </option>
                </select>

                <label for="acyearid"
                  >Grade Setting Type <span class="required">*</span></label
                >
              </span>
            </div>
            <!-- <div class="form-row" > -->
            <div
              v-if="gradeType == 'Mark'"
              class="form-group input-group col-md-3 mt-3"
            >
              <span class="has-float-label">
                <input
                  @input="setTotalRange(totalMarks)"
                  v-model="totalMarks"
                  type="number"
                  class="form-control form-input"
                  id="stdFirstName"
                />
                <label for="clsnameid"
                  >Total Marks <span class="required">*</span></label
                >
              </span>
              <!-- </div> -->
            </div>
          </div>
          <br />
          <!-- <div class="form-row" v-if="gradeType == 'GRADE SETUP'">
          <div class="form-group input-group col-md-2 mt-3">
            <span class="has-float-label">
              <input
                v-model="gradeSetupDetails.marks"
                type="number"
                class="form-control form-input"
                id="stdFirstName"
              />
              <label for="clsnameid">Mark <span class="required">*</span></label>
            </span>
          </div>
        </div> -->
          <div class="form-row" v-if="gradeType == 'Mark'">
            <div class="form-group input-group col-md-2 mt-3">
              <span class="has-float-label">
                <select
                  @change="setTotalToRange(totalMarks, gradeSetupDetails.from)"
                  v-model="gradeSetupDetails.from"
                  class="form-control form-input"
                >
                  <option :value="null">--Select--</option>
                  <option
                    v-for="(item, index) in rangeFromList"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>

                <!-- <input
                v-model="gradeSetupDetails.from"
                type="number"
                class="form-control form-input"
                id="stdFirstName"
              /> -->
                <label for="clsnameid"
                  >FROM <span class="required">*</span></label
                >
              </span>
            </div>
            <div class="form-group input-group col-md-2 mt-3">
              <span class="has-float-label">
                <select
                  v-model="gradeSetupDetails.to"
                  class="form-control form-input"
                >
                  <option :value="null">--Select--</option>
                  <option
                    v-for="(item, index) in rangeToList"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
                <!-- <input
                v-model="gradeSetupDetails.to"
                type="number"
                class="form-control form-input"
                id="stdFirstName"
              /> -->
                <label for="clsnameid"
                  >TO <span class="required">*</span></label
                >
              </span>
            </div>

            <div class="form-group input-group col-md-2 mt-3">
              <span class="has-float-label">
                <input
                  v-model="gradeSetupDetails.grade"
                  type="text"
                  class="form-control form-input"
                  id="stdFirstName"
                />
                <label for="clsnameid"
                  >Grade <span class="required">*</span></label
                >
              </span>
            </div>

            <div class="form-group input-group col-md-2 mt-3">
              <button
                :disabled="!gradeSetupDetails.grade"
                style="width: 45%"
                type="button"
                class="btn btnsml"
                @click.prevent="addMarksRange"
              >
                Add
              </button>
            </div>
          </div>
          <div class="form-row" v-if="gradeType == 'Mark'">
            <div class="form-group col-md-12 mt-3 pr-md-3">
              <div class="tbllist mt-4">
                <div class="restable">
                  <div class="resrow resheader">
                    <div class="cell">Mark Range</div>
                    <div class="cell">Grade</div>
                    <div class="cell" style="text-align: center">Action</div>
                  </div>
                  <div
                    class="resrow"
                    v-for="(data, index) in gradeMarkList"
                    :key="index"
                  >
                    <div class="cell" data-title="Teacher">
                      {{ data.from + " - " + data.to }}
                    </div>
                    <div class="cell" data-title="Teacher" style="width: 164px">
                      <input
                        v-model="data.grade"
                        type="text"
                        class="form-control"
                        id="stdFirstName"
                      />
                      <!-- {{ data.grade }} -->
                    </div>
                    <div
                      class="cell"
                      data-title="Action"
                      style="text-align: center"
                    >
                      <button
                        class="btn round red tooltipt"
                        @click="remove(index, data.from, data.to)"
                      >
                        <i class="far fa-trash-alt"></i>
                        <span class="tooltiptext">Delete</span>
                      </button>
                      <!-- :disabled="index != (gradeMarkList.length - 1)" -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row" v-if="gradeType == 'Attendance Grading Scale'">
            <div class="form-group input-group col-md-2 mt-3">
              <span class="has-float-label">
                <input
                  v-model="gradeSetupDetails.from"
                  type="number"
                  class="form-control form-input"
                  id="stdFirstName"
                />
                <label for="clsnameid"
                  >FROM Mark <span class="required">*</span></label
                >
              </span>
            </div>
            <div class="form-group input-group col-md-2 mt-3">
              <span class="has-float-label">
                <input
                  v-model="gradeSetupDetails.to"
                  type="number"
                  class="form-control form-input"
                  id="stdFirstName"
                />
                <label for="clsnameid"
                  >TO Mark<span class="required">*</span></label
                >
              </span>
            </div>
            <div class="form-group input-group col-md-2 mt-3">
              <span class="has-float-label">
                <input
                  v-model="gradeSetupDetails.marks"
                  type="number"
                  class="form-control form-input"
                  id="stdFirstName"
                />
                <label for="clsnameid"
                  >Mark <span class="required">*</span></label
                >
              </span>
            </div>
            <div class="form-group input-group col-md-2 mt-3">
              <span class="has-float-label">
                <input
                  v-model="gradeSetupDetails.grade"
                  type="text"
                  class="form-control form-input"
                  id="stdFirstName"
                />
                <label for="clsnameid"
                  >Grade <span class="required">*</span></label
                >
              </span>
            </div>
            <div class="form-group input-group col-md-2 mt-3">
              <button
                :disabled="
                  !gradeSetupDetails.from ||
                  !gradeSetupDetails.to ||
                  !gradeSetupDetails.marks ||
                  !gradeSetupDetails.grade
                "
                style="width: 45%"
                type="button"
                class="btn btnsml"
                @click.prevent="saveGradeSetUp('attendance')"
              >
                Save
              </button>
            </div>
          </div>
          <div class="form-row" v-if="gradeType == 'Attendance Grading Scale'">
            <div class="form-group col-md-12 mt-3 pr-md-3">
              <div class="tbllist mt-4">
                <div class="restable">
                  <div class="resrow resheader">
                    <div class="cell">Mark Range</div>
                    <div class="cell">Total Mark</div>
                    <div class="cell">Grade</div>
                    <div class="cell">Action</div>
                  </div>
                  <div
                    class="resrow"
                    v-for="(data, index) in gradeAttendanceList"
                    :key="index"
                  >
                    <div class="cell" data-title="Teacher">
                      {{ data.from + " - " + data.to }}
                    </div>
                    <div class="cell" data-title="Teacher">
                      {{ data.marks }}
                    </div>
                    <div class="cell" data-title="Teacher">
                      <input
                        v-model="data.grade"
                        type="text"
                        class="form-control"
                        id="stdFirstName"
                      />
                      <!-- {{ data.grade }} -->
                    </div>
                    <div class="cell" data-title="Action">
                      <button class="btn round red tooltipt">
                        <i class="far fa-trash-alt"></i>
                        <span class="tooltiptext">Delete</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="pt-3 text-right dk_iconsml"
            style="border-top: 1px solid #e5e5e5"
          >
            <button
              @click="saveGradeSetUp('mark')"
              type="button"
              class="btn btnsml"
              :disabled="gradeMarkList.length == 0"
            >
              Save
            </button>
            <button
              @click="closeGradingMarkPopUp"
              type="button"
              class="btn btncl clsmdl"
              id="clbtn"
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
        <!-- </div> -->
      </b-modal>
      <b-modal id="editGradeSetting" style="width: 40%" size="sm">
        <button
          type="button"
          class="close"
          @click.prevent="closeGradingMarkEditPopUp"
        >
          ×
        </button>

        <!-- <div class="modal-content"> -->
        <div class="contact-form">
          <!-- <span class="close">&times;</span> -->
          <h2>Update Grade Setting</h2>
          <br />
          <div class="form-row">
            <div class="form-group input-group col-md-6 mt-3">
              <span class="has-float-label">
                <select
                  class="form-control form-input"
                  id="Reportingid"
                  disabled
                  name="Reporting"
                  v-model="gradeType"
                >
                  <option :value="null">-- Select --</option>
                  <option
                    v-for="(item, index) in gradeTypes"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </option>
                </select>

                <label for="acyearid"
                  >Grade Setting Type <span class="required">*</span></label
                >
              </span>
            </div>
            <!-- <div class="form-row" > -->
            <div class="form-group input-group col-md-3 mt-3">
              <span class="has-float-label">
                <input
                  @input="setTotalRange(totalMarks)"
                  v-model="totalMarks"
                  type="number"
                  class="form-control form-input"
                  id="stdFirstName"
                />
                <label for="clsnameid"
                  >Marks <span class="required">*</span></label
                >
              </span>
            </div>
          </div>
          <br />

          <div class="form-row">
            <div class="form-group input-group col-md-2 mt-3">
              <span class="has-float-label">
                <select
                  @change="setTotalToRange(totalMarks, gradeSetupDetails.from)"
                  v-model="gradeSetupDetails.from"
                  class="form-control form-input"
                >
                  <option :value="null">--Select--</option>
                  <option
                    v-for="(item, index) in rangeFromList"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>

                <!-- <input
                v-model="gradeSetupDetails.from"
                type="number"
                class="form-control form-input"
                id="stdFirstName"
              /> -->
                <label for="clsnameid"
                  >FROM <span class="required">*</span></label
                >
              </span>
            </div>
            <div class="form-group input-group col-md-2 mt-3">
              <span class="has-float-label">
                <select
                  v-model="gradeSetupDetails.to"
                  class="form-control form-input"
                >
                  <option :value="null">--Select--</option>
                  <option
                    v-for="(item, index) in rangeToList"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
                <!-- <input
                v-model="gradeSetupDetails.to"
                type="number"
                class="form-control form-input"
                id="stdFirstName"
              /> -->
                <label for="clsnameid"
                  >TO <span class="required">*</span></label
                >
              </span>
            </div>

            <div class="form-group input-group col-md-2 mt-3">
              <span class="has-float-label">
                <input
                  v-model="gradeSetupDetails.grade"
                  type="text"
                  class="form-control form-input"
                  id="stdFirstName"
                />
                <label for="clsnameid"
                  >Grade <span class="required">*</span></label
                >
              </span>
            </div>

            <div class="form-group input-group col-md-2 mt-3">
              <button
                :disabled="!gradeSetupDetails.grade"
                style="width: 45%"
                type="button"
                class="btn btnsml"
                @click.prevent="addMarksRangeEdit"
              >
                Add
              </button>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12 mt-3 pr-md-3">
              <div class="tbllist mt-4">
                <div class="restable">
                  <div class="resrow resheader">
                    <div class="cell">Mark Range</div>
                    <div class="cell">Grade</div>
                    <div class="cell" style="text-align: center">Action</div>
                  </div>
                  <div
                    class="resrow"
                    v-for="(data, index) in gradeMarkList"
                    :key="index"
                  >
                    <div class="cell" data-title="Teacher">
                      {{ data.from + " - " + data.to }}
                    </div>
                    <div class="cell" data-title="Teacher" style="width: 164px">
                      <input
                        v-model="data.grade"
                        type="text"
                        class="form-control"
                        id="stdFirstName"
                      />
                      <!-- {{ data.grade }} -->
                    </div>
                    <div
                      class="cell"
                      data-title="Action"
                      style="text-align: center"
                    >
                      <button
                        class="btn round red tooltipt"
                        @click="removeEdit(index, data.from, data.to)"
                      >
                        <i class="far fa-trash-alt"></i>
                        <span class="tooltiptext">Delete</span>
                      </button>
                      <!-- :disabled="index != (gradeMarkList.length - 1)" -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="pt-3 text-right dk_iconsml"
            style="border-top: 1px solid #e5e5e5"
          >
            <button
              @click="updateGradeSetUp"
              type="button"
              class="btn btnsml"
              :disabled="gradeMarkList.length == 0"
            >
              Update
            </button>
            <button
              @click="closeGradingMarkEditPopUp"
              type="button"
              class="btn btncl clsmdl"
              id="clbtn"
            >
              <span>Cancel</span>
            </button>
          </div>
        </div>
        <!-- </div> -->
      </b-modal>
    </div>
    <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
     />
  </div>
</template>
<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import "vue-select/dist/vue-select.css";
import Select from "vue-select";

export default {
  name: "candidates",
  data() {
    return {
      viLoader: false,
      termList: {},
      maxDate: null,
      minDate: null,
      editTermList: [],
      gradeList: [],
      currentTab: 1,
      isEdit: true,
      schoolDayObj: {},

      editId: null,
      totalMarks: null,
      allFromRangeList: [],
      totalMarksEntered: [],
      userDetail: {},
      students: [],
      section: {},
      rangeFromList: [],
      rangeToList: [],
      gradeMarkList: [],
      gradeAttendanceList: [],
      allGradeList: {},
      gradeType: "Mark",
      gradeTypes: [
        {
          label: "Academics Grade Setup",
          value: "Mark",
        },
      ],
      gradeSetupDetails: {
        from: "",
        to: "",
        grade: "",
      },
    };
  },
  created() {
    this.getTerm();
    this.getAccountDetails();
    this.getGradeSetupDetails();
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
  },

  methods: {
    // saveTerm(className,data){

    // },
    async saveTerm(className,data) {

      let isFormValid = false;
      
      if(data.length > 0){
        let checkValues = data.filter( x => x.endDate == null  || x.startDate == null)

        if(checkValues.length > 0){
          isFormValid = false
        }else{
          isFormValid = true
        }

      // await this.$validator.validateAll("classEditvalidate").then((result) => {
      //   isFormValid = result;
      // });

      if (isFormValid) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          const response = await ViService.viXPost(
            `/academic/saveAndUpdateTerms`,
            data,
            userData.token
          );
         // this.viLoader = null;

          if (response.isSuccess) {
            this.getTerm();
            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
            if (response.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
        }
      }else{
        this.$toasted.error('Please Enter Start/End Date');

      }
     }
    },
    async confirmDelete(deleteId,index,data) {


      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        if(deleteId){
       // this.viLoader = true;
        const response = await ViService.viDelete(
          `/academic/deleteClassTerm/${deleteId}`,
          userData.token
        );

        if (response.isSuccess) {
         
        
          this.$toasted.success(response.message);
          this.getTerm();

        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
        }else{
         this.termList[data][data].splice(index, 1);
         this.$toasted.success('Deleted Successfully');


        }
      }
   //   this.viLoader = false;
    },
    noOfDaysLeave(from, to) {
      if (from && to) {
        let dates = [];

        const currentDate = new Date(to);
        const dd = new Date(from);

        const firstDayOfMonth = new Date(
          dd.getFullYear(),
          dd.getMonth(),
          dd.getDate()
        ); // Get the first day of the month

        while (currentDate >= firstDayOfMonth) {
          const formattedDate = currentDate.toLocaleString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
          }); // Format the date as "Monday - May 29"

          const dayValue = currentDate.toLocaleString("en-US", {
            weekday: "long",
          }); // Format the date as "Monday - May 29"

          const currentYear = currentDate.getFullYear();
          const currentMonth = String(currentDate.getMonth() + 1).padStart(
            2,
            "0"
          ); // Months are zero-based, so we add 1
          const currentDay = String(currentDate.getDate()).padStart(2, "0");
          if (this.schoolDayObj[dayValue]) {
            dates.push({
              date: formattedDate,
              createdDate: `${currentYear}-${currentMonth}-${currentDay}`,
            }); // Add the formatted date and other data to the array
          }

          currentDate.setDate(currentDate.getDate() - 1); // Decrement the date by one day
        }
        return dates.length;
      } else {
        return 0;
      }
    },
    setActiveTab(index) {
      this.currentTab = index;
    },
    cancelEdit() {
      (this.isEdit = true), (this.editTermList = []), this.getTerm();
    },
    showView(index) {
      for (let x of Object.values(this.termList)) {
        x.isView = false;
      }

      this.termList[index].isView = true;
    },
    showViewClose(index) {
      this.termList[index].isView = false;
    },
    validateTermDates() {
     // (this.isEdit = false), (this.editTermList = [...this.termList]);
     // let userData = secureUI.sessionGet("user");

      let getDetails = this.$store.getters.getCurrentAcademicYear;

      let termStartDate = new Date(getDetails.from);
      let termEndDate = new Date(getDetails.to);

      // if (getDetails) {
      //   return getDetails
      // }else{
      //   return ''
      // }

      let lastDay = new Date(
        termEndDate.getFullYear(),
        termEndDate.getMonth() + 1,
        0
      );

      const startMonth = String(termStartDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
      const startdate = String(termStartDate.getDate()).padStart(2, "0");

      const endMonth = String(termEndDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
      const enddate = String(lastDay.getDate()).padStart(2, "0");

      let totalMonths = 0;

      totalMonths =
        (termEndDate.getFullYear() - termStartDate.getFullYear()) * 12;
      totalMonths -= termStartDate.getMonth();
      totalMonths += termEndDate.getMonth() + 1;

      if (totalMonths == 13) {
        totalMonths = totalMonths - 1;
      }

      this.minDate = termStartDate.getFullYear() + "-" + startMonth + "-" + startdate;
      this.maxDate = termEndDate.getFullYear() + "-" + endMonth + "-" + enddate;
      // for (let x of this.editTermList) {
      //   (x.startDate = x.startDate ? x.startDate.substring(0, 10) : null),
      //     (x.endDate = x.endDate ? x.endDate.substring(0, 10) : null);
      // }
    },
    setminStartDate(date) {
      let termStartDate = new Date(date);
      termStartDate = new Date(
        termStartDate.setDate(termStartDate.getDate() + 1)
      );

      let startMonth = String(termStartDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
      let startdate = String(termStartDate.getDate()).padStart(2, "0");

      return termStartDate.getFullYear() + "-" + startMonth + "-" + startdate;
    },
    setTermEditDates(startDate, endDate, index, type) {
      //this.classEditObj.terms

      if (startDate && endDate) {
        let d1 = new Date(startDate);
        let d2 = new Date(endDate);

        let months = 0;

        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth() + 1;

        if (months == 13) {
          months = months - 1;
        }

        this.editTermList[index].months = months;
      } else {
        this.editTermList[index].months = 0;
      }

      this.editTermList.forEach((x, i) => {
        if (i > index) {
          x.startDate = null;
          x.endDate = null;
          x.months = null;
        }
        if (i == index) {
          if (type == "start") {
            (x.endDate = null), (x.months = null);
          }
        }
      });
    },
    async getAccountDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        // this.viLoader = true;
        const response = await ViService.viXGet(
          "/account/getAccountSettingConfigDetails?accountId=" +
            userData.userDetails.account._id,
          userData.token
        );

        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data);

          if (details.workingDayList && details.workingDayList.length > 0) {
            this.dayList = [];

            const filteredObjects = details.workingDayList.filter(
              (obj) => obj.isselected
            );

            if (filteredObjects.length) {
              this.dayList = filteredObjects.map((obj) => obj.name);

              const groupByStudentId = this.dayList.reduce((r, a) => {
                r[a] = a; // set the id
                return r;
              }, {});

              this.schoolDayObj = groupByStudentId;
            }
          }
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
        //this.viLoader = false;
      }
    },
    deleteEditTerms(index) {
      this.editTermList.splice(index, 1);
    },
    async updateClass() {
      let isFormValid = false;

      await this.$validator.validateAll("classEditvalidate").then((result) => {
        isFormValid = result;
      });

      if (isFormValid) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          const response = await ViService.viXPost(
            `/academic/termUpdate`,
            this.editTermList,
            userData.token
          );
          this.viLoader = null;

          if (response.isSuccess) {
            (this.isEdit = true), this.getTerm();

            this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
            if (response.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
        }
      }
    },
    addTerms(data) {
      // termList[data][data].push({
      //   _id: null,
      //   topic: '',
      //   noOfClass: null,
      //   startDate: null,
      //   toDate: null,
      //   status: '',
      //   chapterNo: ''
      // })

      this.termList[data][data].push({
        name: "Term" + " " + (this.termList[data][data].length + 1),
        startDate: null,
        endDate: null,
        months: 0,
        _id: null,
        class: data
      });
    },
    async getTerm() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        this.viLoader = true
        const response = await ViService.viXGet(
          `/academic/terms`,
          userData.token
        );
        if (response.isSuccess) {
          let data = secureUI.secureGet(response.data);

          this.termList = data || [];

          //  this.termList = resultData;

          for (let x of Object.keys(this.termList)) {
            for (let y of this.termList[x][x]) {
              (y.startDate = y.startDate ? y.startDate.substring(0, 10) : null),
                (y.endDate = y.endDate ? y.endDate.substring(0, 10) : null);
            }
          }

          this.validateTermDates()

          // if (this.termList.length > 0) {

          //   if (this.termList.length == 1) {
          //   this.selectTerm = this.termList[0]._id;
          //   this.getExamAcademicInfo(this.termList[0]._id,null);

          //   // var startDate = new Date(this.termList[0].startDate);
          //   // var endDate = new Date(this.termList[0].endDate);

          //   // this.minDate = this.dateFormat(startDate)

          //   // this.maxDate =  this.dateFormat(endDate)
          //   }else{
          //   let currentDate = new Date()

          //   let checkData = this.termList.filter( x => (currentDate >= new Date(x.startDate)) && (currentDate <= new Date(x.endDate)))
          //   if(checkData.length > 0){

          //     this.selectTerm = checkData[0]._id;
          //     this.getExamAcademicInfo(checkData[0]._id,null);

          //   }else{
          //     this.selectTerm = this.termList[0]._id;
          //     this.getExamAcademicInfo(this.termList[0]._id,null);
          //   }

          //   }
          // }
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
      this.viLoader = false
    },
    previousRoute() {
      //    let userData = secureUI.sessionGet("user");

      //    let currentRoute = ''
      // if(this.$route.path == '/admission/home' || this.$route.path == '/admission/academicYear' || this.$route.path == '/admission/teacher' || this.$route.path == '/admission/department'  || this.$route.path == '/admission/class' || this.$route.path == '/admission/student' || this.$route.path == '/admission/new' || this.$route.path == '/admission/term/setting'){
      //   currentRoute = '/admission/home'
      // }else if(this.$route.path == '/feesManagement/home' || this.$route.path == '/feesManagement/createFeeType' || this.$route.path == '/feesManagement/createPlan' || this.$route.path == '/feesManagement/adhocfees' || this.$route.path == '/feesManagement/paymentGateWay' || this.$route.path == '/feesManagement/invoice/feePlan'){
      //   currentRoute = '/feesManagement/home'
      // }else{
      //   currentRoute = this.$route.path
      // }
      // localStorage.setItem("previousTab",currentRoute);
      // localStorage.setItem("activeTab",vipath);

      // let data = localStorage.getItem("previousTab");
      localStorage.setItem("activeTab", "/admission/home");
      localStorage.setItem("previousTab", "/admission/home");
      this.$router.push({ path: "/admission/home" });
      // }
    },
    //-------------------- grade setting -----------------

    clearValues() {
      (this.rangeFromList = []),
        (this.rangeToList = []),
        (this.gradeMarkList = []),
        (this.totalMarks = null);
      this.gradeSetupDetails = {
        from: "",
        to: "",
        grade: "",
      };
    },
    setTotalRange(data) {
      (this.rangeFromList = []),
        (this.rangeToList = []),
        (this.gradeMarkList = []),
        (this.allFromRangeList = []),
        (this.totalMarksEntered = []);

      if (data) {
        for (var i = 0; i <= data; i++) {
          this.rangeFromList.push(i);
        }

        this.allFromRangeList = [...this.rangeFromList];

        this.gradeSetupDetails = {
          from: "",
          to: "",
          marks: null,
          grade: "",
        };
      }
    },

    setTotalToRange(totalMark, fromRange) {
      this.rangeToList = [];

      if (totalMark) {
        for (var i = fromRange; i <= totalMark; i++) {
          this.rangeToList.push(i);
        }
      }
    },
    remove(index, from, to) {
      let removeValues = [];
      // if(from && to){
      for (var i = from; i <= to; i++) {
        this.rangeFromList.push(i);
        removeValues.push(i);
      }
      if (this.rangeFromList.length > 0) {
        this.rangeFromList.sort(function (a, b) {
          return a - b;
        });
      }
      // }
      this.gradeMarkList.splice(index, 1);

      let finalTotalMarksEntered = this.removeDuplicateValues(removeValues);
      this.totalMarksEntered = finalTotalMarksEntered;
    },
    removeDuplicateValues(arr1) {
      let arr2 = [...this.totalMarksEntered];
      const modifiedArray = arr2.filter((number) => !arr1.includes(number));
      return modifiedArray;
    },
    removeEdit(index, from, to) {
      let removeValues = [];

      // if(from && to){
      for (var i = from; i <= to; i++) {
        this.rangeFromList.push(i);
        removeValues.push(i);
      }
      if (this.rangeFromList.length > 0) {
        this.rangeFromList.sort(function (a, b) {
          return a - b;
        });
      }
      // }
      this.gradeMarkList.splice(index, 1);

      let finalTotalMarksEntered = this.removeDuplicateValues(removeValues);
      this.totalMarksEntered = finalTotalMarksEntered;
    },

    closeGradingMarkPopUp() {
      this.gradeType = "Mark";
      this.totalMarksEntered = [];
      this.$bvModal.hide("gradeSetting");
      this.gradeSetupDetails = {
        from: "",
        to: "",
        marks: null,
        grade: "",
      };
    },
    closeGradingMarkEditPopUp() {
      this.gradeType = "Mark";
      this.gradeMarkList = [];
      this.totalMarksEntered = [];
      this.totalMarks = null;
      this.editId = null;
      this.$bvModal.hide("editGradeSetting");
      this.gradeSetupDetails = {
        from: "",
        to: "",
        marks: null,
        grade: "",
      };
    },
    //
    addMarksRange() {
      let marksRange = [];

      for (
        var i = this.gradeSetupDetails.from;
        i <= this.gradeSetupDetails.to;
        i++
      ) {
        marksRange.push(i);
      }

      const checkRangeNumbers = this.findCommonNumbers(
        marksRange,
        this.totalMarksEntered
      );

      if (checkRangeNumbers.length == 0) {
        this.gradeMarkList.push({
          from: this.gradeSetupDetails.from,
          to: this.gradeSetupDetails.to,
          grade: this.gradeSetupDetails.grade,
        });

        this.rangeFromList = [];
        this.rangeToList = [];

        if (this.gradeSetupDetails.to) {
          this.gradeSetupDetails.to = this.gradeSetupDetails.to + 1;

          for (
            var i = this.gradeSetupDetails.from;
            i < this.gradeSetupDetails.to;
            i++
          ) {
            this.totalMarksEntered.push(i);
          }

          let final = this.findMissingNumbers(
            this.allFromRangeList,
            this.totalMarksEntered
          );
          this.rangeFromList = [...final];
        }

        this.gradeSetupDetails = {
          from: "",
          to: "",
          grade: "",
        };
      } else {
        this.$toasted.error(
          "The From/To Marks entered are already added to a range, please check. "
        );
      }
    },
    addMarksRangeEdit() {
      let marksRange = [];

      for (
        var i = this.gradeSetupDetails.from;
        i <= this.gradeSetupDetails.to;
        i++
      ) {
        marksRange.push(i);
      }

      const checkRangeNumbers = this.findCommonNumbers(
        marksRange,
        this.totalMarksEntered
      );

      if (checkRangeNumbers.length == 0) {
        this.gradeMarkList.push({
          from: this.gradeSetupDetails.from,
          to: this.gradeSetupDetails.to,
          grade: this.gradeSetupDetails.grade,
        });

        this.rangeFromList = [];
        this.rangeToList = [];

        if (this.gradeSetupDetails.to) {
          this.gradeSetupDetails.to = this.gradeSetupDetails.to + 1;

          for (
            var i = this.gradeSetupDetails.from;
            i < this.gradeSetupDetails.to;
            i++
          ) {
            this.totalMarksEntered.push(i);
          }

          let final = this.findMissingNumbers(
            this.allFromRangeList,
            this.totalMarksEntered
          );
          this.rangeFromList = [...final];
        }

        this.gradeSetupDetails = {
          from: "",
          to: "",
          grade: "",
        };
      } else {
        this.$toasted.error(
          "The From/To Marks entered are already added to a range, please check. "
        );
      }
    },

    openGradingMarkPopUp() {
      // this.gradeType = null
      //  this.$bvModal.show("gradeSetting");
      this.allGradeList.gradeMarkList.push({
        from: "",
        to: "",
        grade: "",
        scale: null
      });
    },
    openGradingMarkEditPopUp(index) {
      this.allGradeList.gradeMarkList.splice(index, 1);

      // this.allFromRangeList = []
      // this.rangeFromList = []
      // this.rangeToList = []
      // let details = this.allGradeList[index]
      // this.editId = details._id
      // this.gradeType = details.gradeType
      // this.gradeMarkList = details.gradeMarkList

      // this.totalMarks = details.totalMarks

      // for (var i = 0; i <= this.totalMarks; i++) {
      //   this.allFromRangeList.push(i);
      // }

      // for (var i = 0; i <= this.totalMarks; i++) {
      //   this.totalMarksEntered.push(i);
      // }

      // //this.allFromRangeList = [...this.rangeFromList]

      // this.$bvModal.show("editGradeSetting");
      // // this.gradeSetupDetails = {
      // //       from: "",
      // //       to: "",
      // //       marks: null,
      // //       grade: "",
      // // };
    },

    async getGradeSetupDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        // let obj = {
        //   class: this.classId,
        //   classroom: this.classRoom,
        // };

        const response = await ViService.viXPost(
          "/academic/getGradeSetupDetails",
          { type: "all" },
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          const data = secureUI.secureGet(response.data);
          this.allGradeList = data;
          //this.gradeMarkList = data.filter((x) => x.type == "mark");
          //this.gradeAttendanceList = data.filter((x) => x.type != "mark");
        } else {
          this.$toasted.error(response.message);
        }
      }
    },

    async saveGradeSetUp(type) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        //let rangeFromAndTo = []

        // let fromRangeData = this.gradeMarkList.map( x => x.from)
        // let toRangeData = this.gradeMarkList.map( x => x.to)
        // rangeFromAndTo = rangeFromAndTo.concat(fromRangeData)
        // rangeFromAndTo = rangeFromAndTo.concat(toRangeData)

        let finalRangeMarks = this.findMissingNumbers(
          this.allFromRangeList,
          this.totalMarksEntered
        );

        if (finalRangeMarks.length == 0) {
          let obj = {
            // class: this.classId,
            // classroom: this.classRoom,
            type: type,
            gradeMarkList: this.gradeMarkList,
            totalMarks: this.totalMarks,
            gradeType: this.gradeType,
          };

          const response = await ViService.viXPost(
            "/academic/gradeSetup/save",
            obj,
            userData.token
          );

          if (response.isSuccess) {
            this.$toasted.success(response.message);
            this.$bvModal.hide("gradeSetting");

            this.getGradeSetupDetails();
            (this.gradeMarkList = []),
              (this.totalMarks = null),
              (this.gradeType = "Mark");
          } else {
            this.$toasted.error(response.message);
          }
        } else {
          this.$toasted.error(
            `Please enter the complete range of marks from 0 to ${this.totalMarks}`
          );
        }
      }
    },
    async updateGradeSetUp() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        // let finalRangeMarks = this.findMissingNumbers(this.allFromRangeList,this.totalMarksEntered)

        // if(finalRangeMarks.length == 0){
        let obj = {
          _id: this.allGradeList._id,
          gradeMarkList: this.allGradeList.gradeMarkList,
        };

        const response = await ViService.viXPost(
          "/academic/gradeSetup/update",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          // this.$bvModal.hide("editGradeSetting");
          // this.editId = null
          this.getGradeSetupDetails();
          //  this.gradeMarkList = [],
          //  this.totalMarks = null,
          //  this.gradeType = 'Mark'
        } else {
          this.$toasted.error(response.message);
        }

        // }else{
        //   this.$toasted.error(` Grade setup is missing for certain range in ${this.totalMarks}`);

        // }
      }
    },
    findMissingNumbers(arr1, arr2) {
      const missingNumbers = [];

      const set = new Set(arr2);

      for (const num of arr1) {
        if (!set.has(num)) {
          missingNumbers.push(num);
        }
      }

      return missingNumbers;
    },
    findCommonNumbers(arr1, arr2) {
      const commonNumbers = [];

      const set = new Set(arr1);

      for (const num of arr2) {
        if (set.has(num)) {
          commonNumbers.push(num);
        }
      }

      return commonNumbers;
    },
  },
  components: {
    "v-select": Select,
    ViSpinner,
    gradesetting: () =>
      import(
        "../Teacher/ViAcademicManagement/ViTeacherAcademicManagementGradeSetting.vue"
      ),
  },
};
</script>
